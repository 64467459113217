@import url(https://use.typekit.net/uor6juq.css);
@-webkit-keyframes spinning {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(380deg);
                transform: rotate(380deg);
    }
    35% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    50% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    75% {
        -webkit-transform: rotate(-20deg);
                transform: rotate(-20deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes spinning {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(380deg);
                transform: rotate(380deg);
    }
    35% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    50% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    75% {
        -webkit-transform: rotate(-20deg);
                transform: rotate(-20deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@-webkit-keyframes spinning-colors {
    0% {
        color: #4e4e4e;
    }
    49% {
        color: #4e4e4e;
    }
    50% {
        color: #cd1719;
    }
    99% {
        color: #cd1719;
    }
    100% {
        color: #4e4e4e;
    }
}

@keyframes spinning-colors {
    0% {
        color: #4e4e4e;
    }
    49% {
        color: #4e4e4e;
    }
    50% {
        color: #cd1719;
    }
    99% {
        color: #cd1719;
    }
    100% {
        color: #4e4e4e;
    }
}

@-webkit-keyframes loading {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

@-webkit-keyframes unpacking {
    0% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package@2x.png');
    }
    50% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cubes@2x.png');
    }
    100% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package@2x.png');
    }
}

@keyframes unpacking {
    0% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package@2x.png');
    }
    50% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cubes@2x.png');
    }
    100% {
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package@2x.png');
    }
}

/**
 * TODO: Add every animation into the type [😻]
 */

/**
 * TODO: [🗾] Get rid of majority of theese styles - scope them OR move to each modules
 */

/* GENERATED WITH generate-icons-list */
/* Warning: Do not edit by hand, all changes will be lost on next execution! */

.icon-abacus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/abacus@2x.png');
    background-size: 20px 20px !important;
}
.menu-blue .icon-abacus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/abacus-w@2x.png');
    background-size: 20px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-abacus:after {
        background-size: 16px 16px !important;
    }
}

.icon-add:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/add@2x.png');
    background-size: 15.2px 15.2px !important;
}
.menu-blue .icon-add:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/add-w@2x.png');
    background-size: 15.2px 15.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-add:after {
        background-size: 12.16px 12.16px !important;
    }
}

.icon-anchor:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/anchor@2x.png');
    background-size: 19.2px 18.4px !important;
}
.menu-blue .icon-anchor:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/anchor-w@2x.png');
    background-size: 19.2px 18.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-anchor:after {
        background-size: 15.36px 14.72px !important;
    }
}

.icon-bin:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/bin@2x.png');
    background-size: 17.6px 22.4px !important;
}
.menu-blue .icon-bin:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/bin-w@2x.png');
    background-size: 17.6px 22.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-bin:after {
        background-size: 14.08px 17.92px !important;
    }
}

.icon-block:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/block@2x.png');
    background-size: 17.6px 23.2px !important;
}
.menu-blue .icon-block:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/block-w@2x.png');
    background-size: 17.6px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-block:after {
        background-size: 14.08px 18.56px !important;
    }
}

.icon-board-clean:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/board-clean@2x.png');
    background-size: 27.2px 19.2px !important;
}
.menu-blue .icon-board-clean:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/board-clean-w@2x.png');
    background-size: 27.2px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-board-clean:after {
        background-size: 21.76px 15.36px !important;
    }
}

.icon-board-options:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/board-options@2x.png');
    background-size: 25.6px 20px !important;
}
.menu-blue .icon-board-options:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/board-options-w@2x.png');
    background-size: 25.6px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-board-options:after {
        background-size: 20.48px 16px !important;
    }
}

.icon-board:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/board@2x.png');
    background-size: 22.4px 17.6px !important;
}
.menu-blue .icon-board:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/board-w@2x.png');
    background-size: 22.4px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-board:after {
        background-size: 17.92px 14.08px !important;
    }
}

.icon-bold:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/bold@2x.png');
    background-size: 12px 16.8px !important;
}
.menu-blue .icon-bold:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/bold-w@2x.png');
    background-size: 12px 16.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-bold:after {
        background-size: 9.6px 13.44px !important;
    }
}

.icon-burger:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/burger@2x.png');
    background-size: 16px 13.6px !important;
}
.menu-blue .icon-burger:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/burger-w@2x.png');
    background-size: 16px 13.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-burger:after {
        background-size: 12.8px 10.88px !important;
    }
}

.icon-chat:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/chat@2x.png');
    background-size: 21.6px 19.2px !important;
}
.menu-blue .icon-chat:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/chat-w@2x.png');
    background-size: 21.6px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-chat:after {
        background-size: 17.28px 15.36px !important;
    }
}

.icon-circle:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/circle@2x.png');
    background-size: 18.4px 17.6px !important;
}
.menu-blue .icon-circle:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/circle-w@2x.png');
    background-size: 18.4px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-circle:after {
        background-size: 14.72px 14.08px !important;
    }
}

.icon-clean:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/clean@2x.png');
    background-size: 20px 20px !important;
}
.menu-blue .icon-clean:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/clean-w@2x.png');
    background-size: 20px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-clean:after {
        background-size: 16px 16px !important;
    }
}

.icon-close-down:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/close-down@2x.png');
    background-size: 18.4px 19.2px !important;
}
.menu-blue .icon-close-down:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/close-down-w@2x.png');
    background-size: 18.4px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-close-down:after {
        background-size: 14.72px 15.36px !important;
    }
}

.icon-cone:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cone@2x.png');
    background-size: 16px 20.8px !important;
}
.menu-blue .icon-cone:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cone-w@2x.png');
    background-size: 16px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-cone:after {
        background-size: 12.8px 16.64px !important;
    }
}

.icon-copy-material:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy-material@2x.png');
    background-size: 24.8px 19.2px !important;
}
.menu-blue .icon-copy-material:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy-material-w@2x.png');
    background-size: 24.8px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-copy-material:after {
        background-size: 19.84px 15.36px !important;
    }
}

.icon-copy-virtual:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy-virtual@2x.png');
    background-size: 25.6px 21.6px !important;
}
.menu-blue .icon-copy-virtual:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy-virtual-w@2x.png');
    background-size: 25.6px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-copy-virtual:after {
        background-size: 20.48px 17.28px !important;
    }
}

.icon-copy:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy@2x.png');
    background-size: 18.4px 23.2px !important;
}
.menu-blue .icon-copy:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy-w@2x.png');
    background-size: 18.4px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-copy:after {
        background-size: 14.72px 18.56px !important;
    }
}

.icon-countdown:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/countdown@2x.png');
    background-size: 16.8px 20.8px !important;
}
.menu-blue .icon-countdown:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/countdown-w@2x.png');
    background-size: 16.8px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-countdown:after {
        background-size: 13.44px 16.64px !important;
    }
}

.icon-cube:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cube@2x.png');
    background-size: 19.2px 20px !important;
}
.menu-blue .icon-cube:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cube-w@2x.png');
    background-size: 19.2px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-cube:after {
        background-size: 15.36px 16px !important;
    }
}

.icon-cubes:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cubes@2x.png');
    background-size: 22.4px 23.2px !important;
}
.menu-blue .icon-cubes:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cubes-w@2x.png');
    background-size: 22.4px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-cubes:after {
        background-size: 17.92px 18.56px !important;
    }
}

.icon-cursor:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cursor@2x.png');
    background-size: 15.2px 21.6px !important;
}
.menu-blue .icon-cursor:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cursor-w@2x.png');
    background-size: 15.2px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-cursor:after {
        background-size: 12.16px 17.28px !important;
    }
}

.icon-cylinder:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cylinder@2x.png');
    background-size: 15.2px 22.4px !important;
}
.menu-blue .icon-cylinder:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/cylinder-w@2x.png');
    background-size: 15.2px 22.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-cylinder:after {
        background-size: 12.16px 17.92px !important;
    }
}

.icon-dice:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/dice@2x.png');
    background-size: 20px 23.2px !important;
}
.menu-blue .icon-dice:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/dice-w@2x.png');
    background-size: 20px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-dice:after {
        background-size: 16px 18.56px !important;
    }
}

.icon-dictation:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/dictation@2x.png');
    background-size: 16.8px 24px !important;
}
.menu-blue .icon-dictation:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/dictation-w@2x.png');
    background-size: 16.8px 24px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-dictation:after {
        background-size: 13.44px 19.2px !important;
    }
}

.icon-down:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/down@2x.png');
    background-size: 11.2px 13.6px !important;
}
.menu-blue .icon-down:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/down-w@2x.png');
    background-size: 11.2px 13.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-down:after {
        background-size: 8.96px 10.88px !important;
    }
}

.icon-earth:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/earth@2x.png');
    background-size: 21.6px 20.8px !important;
}
.menu-blue .icon-earth:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/earth-w@2x.png');
    background-size: 21.6px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-earth:after {
        background-size: 17.28px 16.64px !important;
    }
}

.icon-erase:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/erase@2x.png');
    background-size: 24px 21.6px !important;
}
.menu-blue .icon-erase:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/erase-w@2x.png');
    background-size: 24px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-erase:after {
        background-size: 19.2px 17.28px !important;
    }
}

.icon-file-image:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/file-image@2x.png');
    background-size: 16.8px 23.2px !important;
}
.menu-blue .icon-file-image:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/file-image-w@2x.png');
    background-size: 16.8px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-file-image:after {
        background-size: 13.44px 18.56px !important;
    }
}

.icon-file-pdf:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/file-pdf@2x.png');
    background-size: 16.8px 23.2px !important;
}
.menu-blue .icon-file-pdf:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/file-pdf-w@2x.png');
    background-size: 16.8px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-file-pdf:after {
        background-size: 13.44px 18.56px !important;
    }
}

.icon-flag:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/flag@2x.png');
    background-size: 16px 20px !important;
}
.menu-blue .icon-flag:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/flag-w@2x.png');
    background-size: 16px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-flag:after {
        background-size: 12.8px 16px !important;
    }
}

.icon-font-size-1:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/font-size-1@2x.png');
    background-size: 28px 28px !important;
}
.menu-blue .icon-font-size-1:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/font-size-1-w@2x.png');
    background-size: 28px 28px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-font-size-1:after {
        background-size: 22.4px 22.4px !important;
    }
}

.icon-font-size-2:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/font-size-2@2x.png');
    background-size: 28px 28px !important;
}
.menu-blue .icon-font-size-2:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/font-size-2-w@2x.png');
    background-size: 28px 28px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-font-size-2:after {
        background-size: 22.4px 22.4px !important;
    }
}

.icon-font-size-3:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/font-size-3@2x.png');
    background-size: 28px 28px !important;
}
.menu-blue .icon-font-size-3:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/font-size-3-w@2x.png');
    background-size: 28px 28px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-font-size-3:after {
        background-size: 22.4px 22.4px !important;
    }
}

.icon-fullscreen-off:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/fullscreen-off@2x.png');
    background-size: 16.8px 16px !important;
}
.menu-blue .icon-fullscreen-off:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/fullscreen-off-w@2x.png');
    background-size: 16.8px 16px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-fullscreen-off:after {
        background-size: 13.44px 12.8px !important;
    }
}

.icon-fullscreen-on:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/fullscreen-on@2x.png');
    background-size: 16.8px 16px !important;
}
.menu-blue .icon-fullscreen-on:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/fullscreen-on-w@2x.png');
    background-size: 16.8px 16px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-fullscreen-on:after {
        background-size: 13.44px 12.8px !important;
    }
}

.icon-group:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/group@2x.png');
    background-size: 19.2px 20px !important;
}
.menu-blue .icon-group:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/group-w@2x.png');
    background-size: 19.2px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-group:after {
        background-size: 15.36px 16px !important;
    }
}

.icon-hand:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/hand@2x.png');
    background-size: 20.8px 23.2px !important;
}
.menu-blue .icon-hand:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/hand-w@2x.png');
    background-size: 20.8px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-hand:after {
        background-size: 16.64px 18.56px !important;
    }
}

.icon-hejny:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/hejny@2x.png');
    background-size: 20.8px 20.8px !important;
}
.menu-blue .icon-hejny:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/hejny-w@2x.png');
    background-size: 20.8px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-hejny:after {
        background-size: 16.64px 16.64px !important;
    }
}

.icon-home-center:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/home-center@2x.png');
    background-size: 24.8px 24.8px !important;
}
.menu-blue .icon-home-center:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/home-center-w@2x.png');
    background-size: 24.8px 24.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-home-center:after {
        background-size: 19.84px 19.84px !important;
    }
}

.icon-home:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/home@2x.png');
    background-size: 19.2px 19.2px !important;
}
.menu-blue .icon-home:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/home-w@2x.png');
    background-size: 19.2px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-home:after {
        background-size: 15.36px 15.36px !important;
    }
}

.icon-image:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/image@2x.png');
    background-size: 22.4px 16.8px !important;
}
.menu-blue .icon-image:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/image-w@2x.png');
    background-size: 22.4px 16.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-image:after {
        background-size: 17.92px 13.44px !important;
    }
}

.icon-italic:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/italic@2x.png');
    background-size: 10.4px 17.6px !important;
}
.menu-blue .icon-italic:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/italic-w@2x.png');
    background-size: 10.4px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-italic:after {
        background-size: 8.32px 14.08px !important;
    }
}

.icon-key:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/key@2x.png');
    background-size: 20.8px 20.8px !important;
}
.menu-blue .icon-key:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/key-w@2x.png');
    background-size: 20.8px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-key:after {
        background-size: 16.64px 16.64px !important;
    }
}

.icon-left:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/left@2x.png');
    background-size: 14.4px 11.2px !important;
}
.menu-blue .icon-left:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/left-w@2x.png');
    background-size: 14.4px 11.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-left:after {
        background-size: 11.52px 8.96px !important;
    }
}

.icon-line:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/line@2x.png');
    background-size: 16.8px 17.6px !important;
}
.menu-blue .icon-line:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/line-w@2x.png');
    background-size: 16.8px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-line:after {
        background-size: 13.44px 14.08px !important;
    }
}

.icon-link:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/link@2x.png');
    background-size: 23.2px 23.2px !important;
}
.menu-blue .icon-link:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/link-w@2x.png');
    background-size: 23.2px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-link:after {
        background-size: 18.56px 18.56px !important;
    }
}

.icon-list:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/list@2x.png');
    background-size: 22.4px 22.4px !important;
}
.menu-blue .icon-list:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/list-w@2x.png');
    background-size: 22.4px 22.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-list:after {
        background-size: 17.92px 17.92px !important;
    }
}

.icon-lock:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/lock@2x.png');
    background-size: 18.4px 22.4px !important;
}
.menu-blue .icon-lock:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/lock-w@2x.png');
    background-size: 18.4px 22.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-lock:after {
        background-size: 14.72px 17.92px !important;
    }
}

.icon-mic:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/mic@2x.png');
    background-size: 11.2px 20px !important;
}
.menu-blue .icon-mic:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/mic-w@2x.png');
    background-size: 11.2px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-mic:after {
        background-size: 8.96px 16px !important;
    }
}

.icon-minus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/minus@2x.png');
    background-size: 14.4px 14.4px !important;
}
.menu-blue .icon-minus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/minus-w@2x.png');
    background-size: 14.4px 14.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-minus:after {
        background-size: 11.52px 11.52px !important;
    }
}

.icon-moon:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/moon@2x.png');
    background-size: 13.6px 17.6px !important;
}
.menu-blue .icon-moon:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/moon-w@2x.png');
    background-size: 13.6px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-moon:after {
        background-size: 10.88px 14.08px !important;
    }
}

.icon-no:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/no@2x.png');
    background-size: 15.2px 15.2px !important;
}
.menu-blue .icon-no:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/no-w@2x.png');
    background-size: 15.2px 15.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-no:after {
        background-size: 12.16px 12.16px !important;
    }
}

.icon-numbers:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/numbers@2x.png');
    background-size: 22.4px 22.4px !important;
}
.menu-blue .icon-numbers:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/numbers-w@2x.png');
    background-size: 22.4px 22.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-numbers:after {
        background-size: 17.92px 17.92px !important;
    }
}

.icon-options:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/options@2x.png');
    background-size: 19.2px 19.2px !important;
}
.menu-blue .icon-options:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/options-w@2x.png');
    background-size: 19.2px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-options:after {
        background-size: 15.36px 15.36px !important;
    }
}

.icon-order-back:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-back@2x.png');
    background-size: 16.8px 16.8px !important;
}
.menu-blue .icon-order-back:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-back-w@2x.png');
    background-size: 16.8px 16.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-order-back:after {
        background-size: 13.44px 13.44px !important;
    }
}

.icon-order-bottom:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-bottom@2x.png');
    background-size: 20px 20px !important;
}
.menu-blue .icon-order-bottom:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-bottom-w@2x.png');
    background-size: 20px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-order-bottom:after {
        background-size: 16px 16px !important;
    }
}

.icon-order-front:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-front@2x.png');
    background-size: 16.8px 16.8px !important;
}
.menu-blue .icon-order-front:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-front-w@2x.png');
    background-size: 16.8px 16.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-order-front:after {
        background-size: 13.44px 13.44px !important;
    }
}

.icon-order-top:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-top@2x.png');
    background-size: 20.8px 20px !important;
}
.menu-blue .icon-order-top:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/order-top-w@2x.png');
    background-size: 20.8px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-order-top:after {
        background-size: 16.64px 16px !important;
    }
}

.icon-package:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package@2x.png');
    background-size: 28px 23.2px !important;
}
.menu-blue .icon-package:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package-w@2x.png');
    background-size: 28px 23.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-package:after {
        background-size: 22.4px 18.56px !important;
    }
}

.icon-pen-3D:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/pen-3D@2x.png');
    background-size: 24px 22.4px !important;
}
.menu-blue .icon-pen-3D:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/pen-3D-w@2x.png');
    background-size: 24px 22.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-pen-3D:after {
        background-size: 19.2px 17.92px !important;
    }
}

.icon-pen:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/pen@2x.png');
    background-size: 21.6px 21.6px !important;
}
.menu-blue .icon-pen:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/pen-w@2x.png');
    background-size: 21.6px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-pen:after {
        background-size: 17.28px 17.28px !important;
    }
}

.icon-planet-1:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-1@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-1:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-1-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-1:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-2:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-2@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-2:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-2-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-2:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-3:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-3@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-3:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-3-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-3:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-4:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-4@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-4:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-4-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-4:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-5:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-5@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-5:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-5-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-5:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-6:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-6@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-6:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-6-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-6:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-7:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-7@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-7:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-7-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-7:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-planet-8:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-8@2x.png');
    background-size: 27.2px 27.2px !important;
}
.menu-blue .icon-planet-8:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/planet-8-w@2x.png');
    background-size: 27.2px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-planet-8:after {
        background-size: 21.76px 21.76px !important;
    }
}

.icon-platonic-12:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-12@2x.png');
    background-size: 23.2px 26.4px !important;
}
.menu-blue .icon-platonic-12:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-12-w@2x.png');
    background-size: 23.2px 26.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-platonic-12:after {
        background-size: 18.56px 21.12px !important;
    }
}

.icon-platonic-20:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-20@2x.png');
    background-size: 23.2px 26.4px !important;
}
.menu-blue .icon-platonic-20:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-20-w@2x.png');
    background-size: 23.2px 26.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-platonic-20:after {
        background-size: 18.56px 21.12px !important;
    }
}

.icon-platonic-4:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-4@2x.png');
    background-size: 20.8px 25.6px !important;
}
.menu-blue .icon-platonic-4:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-4-w@2x.png');
    background-size: 20.8px 25.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-platonic-4:after {
        background-size: 16.64px 20.48px !important;
    }
}

.icon-platonic-6:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-6@2x.png');
    background-size: 20.8px 26.4px !important;
}
.menu-blue .icon-platonic-6:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-6-w@2x.png');
    background-size: 20.8px 26.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-platonic-6:after {
        background-size: 16.64px 21.12px !important;
    }
}

.icon-platonic-8:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-8@2x.png');
    background-size: 24px 27.2px !important;
}
.menu-blue .icon-platonic-8:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/platonic-8-w@2x.png');
    background-size: 24px 27.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-platonic-8:after {
        background-size: 19.2px 21.76px !important;
    }
}

.icon-plus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/plus@2x.png');
    background-size: 14.4px 14.4px !important;
}
.menu-blue .icon-plus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/plus-w@2x.png');
    background-size: 14.4px 14.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-plus:after {
        background-size: 11.52px 11.52px !important;
    }
}

.icon-pyramid:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/pyramid@2x.png');
    background-size: 16.8px 21.6px !important;
}
.menu-blue .icon-pyramid:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/pyramid-w@2x.png');
    background-size: 16.8px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-pyramid:after {
        background-size: 13.44px 17.28px !important;
    }
}

.icon-redo:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/redo@2x.png');
    background-size: 13.6px 14.4px !important;
}
.menu-blue .icon-redo:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/redo-w@2x.png');
    background-size: 13.6px 14.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-redo:after {
        background-size: 10.88px 11.52px !important;
    }
}

.icon-reset:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/reset@2x.png');
    background-size: 15.2px 16px !important;
}
.menu-blue .icon-reset:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/reset-w@2x.png');
    background-size: 15.2px 16px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-reset:after {
        background-size: 12.16px 12.8px !important;
    }
}

.icon-right:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/right@2x.png');
    background-size: 14.4px 11.2px !important;
}
.menu-blue .icon-right:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/right-w@2x.png');
    background-size: 14.4px 11.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-right:after {
        background-size: 11.52px 8.96px !important;
    }
}

.icon-run:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/run@2x.png');
    background-size: 13.6px 14.4px !important;
}
.menu-blue .icon-run:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/run-w@2x.png');
    background-size: 13.6px 14.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-run:after {
        background-size: 10.88px 11.52px !important;
    }
}

.icon-search:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/search@2x.png');
    background-size: 20px 20px !important;
}
.menu-blue .icon-search:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/search-w@2x.png');
    background-size: 20px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-search:after {
        background-size: 16px 16px !important;
    }
}

.icon-send:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/send@2x.png');
    background-size: 20.8px 17.6px !important;
}
.menu-blue .icon-send:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/send-w@2x.png');
    background-size: 20.8px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-send:after {
        background-size: 16.64px 14.08px !important;
    }
}

.icon-shapes:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/shapes@2x.png');
    background-size: 25.6px 21.6px !important;
}
.menu-blue .icon-shapes:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/shapes-w@2x.png');
    background-size: 25.6px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-shapes:after {
        background-size: 20.48px 17.28px !important;
    }
}

.icon-share:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/share@2x.png');
    background-size: 17.6px 16px !important;
}
.menu-blue .icon-share:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/share-w@2x.png');
    background-size: 17.6px 16px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-share:after {
        background-size: 14.08px 12.8px !important;
    }
}

.icon-sphere:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/sphere@2x.png');
    background-size: 20.8px 20.8px !important;
}
.menu-blue .icon-sphere:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/sphere-w@2x.png');
    background-size: 20.8px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-sphere:after {
        background-size: 16.64px 16.64px !important;
    }
}

.icon-square:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/square@2x.png');
    background-size: 19.2px 14.4px !important;
}
.menu-blue .icon-square:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/square-w@2x.png');
    background-size: 19.2px 14.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-square:after {
        background-size: 15.36px 11.52px !important;
    }
}

.icon-start:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/start@2x.png');
    background-size: 13.6px 14.4px !important;
}
.menu-blue .icon-start:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/start-w@2x.png');
    background-size: 13.6px 14.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-start:after {
        background-size: 10.88px 11.52px !important;
    }
}

.icon-stroke-1:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/stroke-1@2x.png');
    background-size: 19.2px 18.4px !important;
}
.menu-blue .icon-stroke-1:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/stroke-1-w@2x.png');
    background-size: 19.2px 18.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-stroke-1:after {
        background-size: 15.36px 14.72px !important;
    }
}

.icon-stroke-2:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/stroke-2@2x.png');
    background-size: 21.6px 20px !important;
}
.menu-blue .icon-stroke-2:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/stroke-2-w@2x.png');
    background-size: 21.6px 20px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-stroke-2:after {
        background-size: 17.28px 16px !important;
    }
}

.icon-stroke-3:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/stroke-3@2x.png');
    background-size: 25.6px 24.8px !important;
}
.menu-blue .icon-stroke-3:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/stroke-3-w@2x.png');
    background-size: 25.6px 24.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-stroke-3:after {
        background-size: 20.48px 19.84px !important;
    }
}

.icon-sun:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/sun@2x.png');
    background-size: 17.6px 18.4px !important;
}
.menu-blue .icon-sun:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/sun-w@2x.png');
    background-size: 17.6px 18.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-sun:after {
        background-size: 14.08px 14.72px !important;
    }
}

.icon-text:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/text@2x.png');
    background-size: 26.4px 17.6px !important;
}
.menu-blue .icon-text:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/text-w@2x.png');
    background-size: 26.4px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-text:after {
        background-size: 21.12px 14.08px !important;
    }
}

.icon-timer:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/timer@2x.png');
    background-size: 18.4px 21.6px !important;
}
.menu-blue .icon-timer:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/timer-w@2x.png');
    background-size: 18.4px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-timer:after {
        background-size: 14.72px 17.28px !important;
    }
}

.icon-todo:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/todo@2x.png');
    background-size: 20.8px 20.8px !important;
}
.menu-blue .icon-todo:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/todo-w@2x.png');
    background-size: 20.8px 20.8px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-todo:after {
        background-size: 16.64px 16.64px !important;
    }
}

.icon-torus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/torus@2x.png');
    background-size: 27.2px 17.6px !important;
}
.menu-blue .icon-torus:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/torus-w@2x.png');
    background-size: 27.2px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-torus:after {
        background-size: 21.76px 14.08px !important;
    }
}

.icon-triangle-right:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/triangle-right@2x.png');
    background-size: 16px 19.2px !important;
}
.menu-blue .icon-triangle-right:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/triangle-right-w@2x.png');
    background-size: 16px 19.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-triangle-right:after {
        background-size: 12.8px 15.36px !important;
    }
}

.icon-triangle:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/triangle@2x.png');
    background-size: 20px 18.4px !important;
}
.menu-blue .icon-triangle:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/triangle-w@2x.png');
    background-size: 20px 18.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-triangle:after {
        background-size: 16px 14.72px !important;
    }
}

.icon-underline:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/underline@2x.png');
    background-size: 12.8px 17.6px !important;
}
.menu-blue .icon-underline:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/underline-w@2x.png');
    background-size: 12.8px 17.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-underline:after {
        background-size: 10.24px 14.08px !important;
    }
}

.icon-undo:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/undo@2x.png');
    background-size: 14.4px 15.2px !important;
}
.menu-blue .icon-undo:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/undo-w@2x.png');
    background-size: 14.4px 15.2px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-undo:after {
        background-size: 11.52px 12.16px !important;
    }
}

.icon-up:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/up@2x.png');
    background-size: 11.2px 13.6px !important;
}
.menu-blue .icon-up:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/up-w@2x.png');
    background-size: 11.2px 13.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-up:after {
        background-size: 8.96px 10.88px !important;
    }
}

.icon-visibility:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/visibility@2x.png');
    background-size: 24px 18.4px !important;
}
.menu-blue .icon-visibility:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/visibility-w@2x.png');
    background-size: 24px 18.4px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-visibility:after {
        background-size: 19.2px 14.72px !important;
    }
}

.icon-yes:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/yes@2x.png');
    background-size: 16.8px 12px !important;
}
.menu-blue .icon-yes:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/yes-w@2x.png');
    background-size: 16.8px 12px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-yes:after {
        background-size: 13.44px 9.6px !important;
    }
}

.icon-zoom-in:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/zoom-in@2x.png');
    background-size: 20.8px 21.6px !important;
}
.menu-blue .icon-zoom-in:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/zoom-in-w@2x.png');
    background-size: 20.8px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-zoom-in:after {
        background-size: 16.64px 17.28px !important;
    }
}

.icon-zoom-out:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/zoom-out@2x.png');
    background-size: 20.8px 21.6px !important;
}
.menu-blue .icon-zoom-out:after {
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/zoom-out-w@2x.png');
    background-size: 20.8px 21.6px !important;
}
@media /*[🍕]*/ only screen and (max-width: 575px) {
    .icon-zoom-out:after {
        background-size: 16.64px 17.28px !important;
    }
}

.social {
    text-decoration: none;
    color: white !important;
    background: #f2f2f2;
    border-radius: 5px;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    position: relative;
}

.social:hover {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.social.social-banner {
    display: table;
    margin-bottom: 10px;
    padding: 8px 15px;
    padding-left: 45px;
    font-size: 0.9em;
    background-size: 38px;
}

.social.social-icon {
    display: inline-block;
    width: 26px;
    height: 26px;
}

.social.facebook {
    background-color: #3b5998;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/facebook@2x.png');
}

.social.instagram {
    background-color: #f7504e;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/instagram@2x.png');
}

.social.twitter {
    background-color: #55acee;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/twitter@2x.png');
}

.social.linkedin {
    background-color: #006699;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/linkedin@2x.png');
}

.social.telegram {
    background-color: #28a0d3;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/telegram@2x.png');
}

.social.github {
    background-color: #1b1f23;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/github@2x.png');
}

.social.mail {
    background-color: #009edf;
    background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/socials/mail@2x.png');
}

.social-group {
    margin-bottom: 30px;
    margin-top: -10px;
}

.contact {
    display: block;
    position: relative;
    padding: 0 20px;
    margin: 20px 0;
    width: 100%;
}

.contact .image {
    display: block;
    width: 50%;
    height: calc(100% + 40px);
    margin-top: -20px;
    top: 0;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.contact.contact-left {
    text-align: left;
    padding-right: 50%;
}

.contact.contact-right {
    text-align: right;
    padding-left: 50%;
}

.contact.contact-left .image {
    right: 0;
}

.contact.contact-right .image {
    left: 0;
}

.contact p {
    opacity: 0.5;
    font-size: 0.9em;
    line-height: 1.3em;
    display: block;
    min-height: 100px;
}

.contact .socials {
    margin-top: -20px;
}

@media /*[🍕]*/ only screen and (max-width: 850px) {
    .contact .image {
        display: block;
        width: 100%;
        height: 200px;
        top: unset;
        position: unset;
        margin-top: 0px;
        margin-bottom: -20px;
    }
    .contact.contact-left {
        padding-right: 0;
    }

    .contact.contact-right {
        padding-left: 0;
        text-align: left;
    }
}

/*@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');*/

/**
 * Note: This is a hack to prevent CRA invisible error window to appear on top blocking whole app
 *       @see https://stackoverflow.com/a/55036909/10647824
 */
iframe:not(.wanted-iframe) {
    display: none !important;
}

/**
 * TODO: [🗾] Get rid of majority of theese styles - scope them OR move to each modules
 */

