/*@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');*/
@import url('https://use.typekit.net/uor6juq.css');
@import url('./social.css');

/**
 * Note: This is a hack to prevent CRA invisible error window to appear on top blocking whole app
 *       @see https://stackoverflow.com/a/55036909/10647824
 */
iframe:not(.wanted-iframe) {
    display: none !important;
}

/**
 * TODO: [🗾] Get rid of majority of theese styles - scope them OR move to each modules
 */
